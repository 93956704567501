// 在职员工列表
<template>
    <div class="">
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="姓名"
                              prop="userName">
                    <el-input v-model="searchForm.userName"
                              placeholder="请输入姓名"
                              clearable />
                </el-form-item>

                <el-form-item label="部门"
                              prop="deptName">
                    <el-input v-model="searchForm.deptName"
                              placeholder="请输入部门"
                              clearable />
                </el-form-item>

                <el-form-item label="职位"
                              prop="job">
                    <el-input v-model="searchForm.job"
                              placeholder="请输入姓名"
                              clearable />
                </el-form-item>

                <el-form-item label="员工类型"
                              prop="workerType">
                    <el-select v-model="searchForm.workerType"
                               placeholder="请选择">
                        <el-option v-for="(item,index) in userOpt"
                                   :key="index"
                                   :label="item.label"
                                   :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="是否激活"
                              prop="isActivate">
                    <el-select v-model="searchForm.isActivate"
                               placeholder="请选择">
                        <el-option v-for="(item,index) in activation"
                                   :key="index"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary"
                               @click="search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData"
                          border
                          v-loading="isloading"
                          :height="tableHeight"
                          stripe>
                    <el-table-column type="index"
                                     width="50"
                                     label="序号">
                    </el-table-column>
                    <el-table-column prop="userName"
                                     width="200"
                                     label="姓名">
                    </el-table-column>
                    <el-table-column prop="deptName"
                                     width="200"
                                     label="部门">
                    </el-table-column>
                    <el-table-column prop="job"
                                     label="职位">
                    </el-table-column>
                    <el-table-column prop="workerType"
                                     width="130"
                                     label="员工类型">
                    </el-table-column>
                    <el-table-column prop="workerStatus.text"
                                     width="130"
                                     label="员工状态">
                    </el-table-column>

                    <el-table-column prop="tel"
                                     width="200"
                                     label="手机号">
                    </el-table-column>

                    <el-table-column prop="entryDate"
                                     width="200"
                                     label="入职日期">
                    </el-table-column>

                    <el-table-column prop="weiXinAccount"
                                     width="100"
                                     label="是否激活">

                        <template slot="header">
                            是否激活
                            <el-tooltip class="item"
                                        effect="dark"
                                        content="系统根据微信绑定状态自动判断"
                                        placement="top">
                                <i class="el-icon-question"
                                   style="font-size:16px"></i>
                            </el-tooltip>
                        </template>

                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.weiXinAccount"
                                    type="success">已激活</el-tag>
                            <el-tag v-else
                                    type="danger">未激活</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作"
                                     width="130">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">详情</el-link>
                            <!-- <el-popconfirm confirmButtonText='确认'
                                           cancelButtonText='取消'
                                           @confirm="deleteTable(scope.row)"
                                           title="你确定解除微信的绑定吗？">
                                <el-link slot="reference"
                                         v-if="scope.row.weiXinAccount"
                                         type='danger'>取消激活</el-link>
                            </el-popconfirm> -->
                            <el-link slot="reference"
                                @click="deleteTable(scope.row)"
                                v-if="scope.row.weiXinAccount"
                                type='danger'>取消激活</el-link>
                        </template>
                    </el-table-column>

                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
    </div>
</template>

<script>
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
import Pagination from "@/components/Pagination"; // 分页
export default {
    name: "onjobList",
    props: {
        //人员状态
        type: {
            type: String,
            default: "",
        },

        //触发更新
        refresh: {
            type: Number
        },
    },

    watch: {
        refresh: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal && (this.getList());
            },
        },
    },

    components: {
        Pagination,
    },

    data() {
        return {
            dialogVisible: false,
            searchForm: {
                userName: '', //姓名
                job: "", //职位
                deptName: "", //部门名称
                workerType: "", //员工类型
                isActivate: "", //是否激活
                page: 1,
                size: 20,
                sort:"entry_date",
                order:"desc"
            },
            userOpt: this.commonJs.getSelectByType('WorkerType',"userOpt",this),
            activation: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: true,
                    label: "已激活",
                },
                {
                    value: false,
                    label: "未激活",
                },
            ],
            pageTotal: 0,
            isloading: false,
            rowData: null,
            tableData: [{id:1}],
        };
    },

    created() {},

    mounted() {
        this.search();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            humanResourcesManageApi
                .getOnlineUser(
                    Object.assign(searchForm, {
                        workerStatuses: this.type.split(","),
                    })
                )
                .then((res) => {
                    this.tableData = res.content.records;
                    this.pageTotal = res.content.total;
                    this.$emit("contractTypeNum", {
                        type: this.type,
                        num: this.pageTotal,
                    }); //设置总数
                })
                .finally(()=>{
                    this.isloading = false;
                });
        },

        //查看详情
        details(e) {
            this.goDetrail({
                data: JSON.stringify(Object.assign({}, e)),
            });
        },

        //前往详情页
        goDetrail(data) {
            this.$router.push({
                path: "/humanResourcesManage/detailContainer",
                query: data,
            });
        },
        // 搜索
        search() {
            this.defaultSearch();
        },

        // 清空筛选条件
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },

        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.dialogVisible = false;
            this.defaultSearch();
        },

        // 默认查询第一页
        defaultSearch() {
            this.searchForm.page = 1;
            this.getList();
        },

        // 表格的編輯操作
        editTable(e) {
            this.rowData = e;
            this.dialogVisible = true;
        },
        // 解除微信绑定
        deleteTable(row) {
            this.$confirm('你确定解除微信的绑定吗？', '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.isloading = true;
                humanResourcesManageApi
                    .cancelActivatedByid(row.userId)
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "恭喜！已解除微信的绑定",
                        });
                        this.getList();
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            })
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 340 });
        },
    },
};
</script>

<style lang='scss' scoped>
</style>
